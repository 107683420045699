import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { yupResolver } from "@hookform/resolvers/yup";

import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { useUpdateAbonent } from "hooks/api/management";
import { SModalTitle } from "components/modal-notify-body/styles";
import { PHONE_MASK } from "stream-constants/keys-and-constants";
import { makeDefaultForEdit, schemaEdit } from "./validation-schema";
import { Spacer } from "components/spacer/spacer.component";
import { unformatPhone } from "utils/formatters";
import { SBtns, SFormField } from "./styles";

type IProps = {
  onCancel: Function;
  data: any;
};

export const FormEditAbonent: FC<IProps> = ({ onCancel, data }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { dirtyFields, errors, isDirty },
    reset,
  } = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schemaEdit),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });
  const defaultData = makeDefaultForEdit(data);

  const mutation = useUpdateAbonent();

  const onSubmit = (submData) => {
    mutation.mutate({
      username: submData.username,
      user_id: data.user_id,
      contract_num: submData.contract_num,
      phone: dirtyFields["phone"] != null ? unformatPhone(submData.phone) : defaultData.phone!,
      email: submData.email,
    });
    onCancel();
  };

  useEffect(() => {
    reset(defaultData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, data]);

  return (
    <>
      <SModalTitle>Редактировать данные абонента</SModalTitle>
      <Spacer value="48px" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <SFormField>
          <MTSInput
            label="Номер договора"
            control={control}
            {...register("contract_num", {
              required: true,
            })}
            errorMessage={errors?.contract_num?.message}
          />
        </SFormField>
        <SFormField>
          <MTSInput
            label="ФИО"
            control={control}
            {...register("username", {
              required: true,
            })}
            errorMessage={errors?.username?.message}
          />
        </SFormField>
        <SFormField>
          <InputMask mask={PHONE_MASK} {...register("phone")}>
            {() => (
              <MTSInput
                label="Телефон"
                {...register("phone", {
                  required: true,
                })}
                control={control}
                errorMessage={errors?.phone?.message}
              />
            )}
          </InputMask>
        </SFormField>
        <SFormField>
          <MTSInput
            label="Электронная почта"
            {...register("email", {
              required: true,
            })}
            control={control}
            errorMessage={errors?.email?.message}
          />
        </SFormField>
        <SBtns>
          <MTSButton
            size="M"
            variant="primary"
            type="submit"
            onClick={() => undefined}
            disabled={!isDirty}
          >
            Подтвердить
          </MTSButton>
          <MTSButton size="M" variant="secondary" onClick={() => onCancel()}>
            Отмена
          </MTSButton>
        </SBtns>
      </form>
    </>
  );
};
