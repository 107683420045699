import { useMutation, useQuery, useQueryClient, useInfiniteQuery, InfiniteData } from "react-query";
import { format } from "date-fns";
import { isEmpty } from "lodash";

import { replaceDashboardItem, replaceGeoItem } from "hooks/utils";
import {
  toastDelFail,
  toastDelSuc,
  toastSaveFail,
  toastSaveSuc,
} from "components/analytics-research/tableFav/toastHelpers";
import {
  IEditFavlistReq,
  IResearch,
  IResearchChartReq,
  IResearchChartRes,
  IResearchFavslistRes,
  IResearchGeoReq,
  IResearchHandbookRes,
  IResearchMapReq,
  IResearchPoResponse,
  IResearchPriemResponse,
  IResearchResponse,
  IResearchSetStatusReq,
  IResearchSetTicketReq,
  IResearchStatInfoRes,
  IResearchStatResResponse,
  IResearchStatsArchiveRes,
} from "interface/analytics";
import {
  researchReportDashboard,
  getResearchDevice,
  getResearchChart,
  researchCheckVisibleColumns,
  researchDeleteFavlist,
  researchEditFavlist,
  researchFavsList,
  researchFindOnMap,
  researchHandbook,
  researchLoadColumns,
  researchLoadFilter,
  researchSaveColumns,
  researchSaveFilter,
  researchSingleFavlist,
  researchReportList,
  analSetTicket,
  analSetStatus,
  analSetFb,
  analSetBd,
  analSetT3,
  analSetT2,
  getAnalStatRes,
  getAnalStatInfo,
  getAnalStatsArchive,
  researchFindGeo,
  analSetT0,
  analSetT1,
} from "services/research";
import { Keys, query_objects_limit } from "./const";
import { getResearchPoList, getResearchPriemList } from "services/filters-research";

export function useResearchDevice(isFilterOn: boolean) {
  return useInfiniteQuery<any, { description?: string }, IResearchResponse>(
    [Keys.RESEARCH, "research", isFilterOn],
    ({ pageParam = 0 }) =>
      getResearchDevice({
        page_number: pageParam,
        limit: query_objects_limit,
        use_filter: isFilterOn,
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.offset >= lastPage.count ? undefined : lastPage.offset / lastPage?.limit;
      },
    },
  );
}

export function useResearchChart(data: IResearchChartReq) {
  return useQuery<IResearchChartReq, any, IResearchChartRes[]>(
    [Keys.RESEARCH, "chart", data],
    () => getResearchChart(data),
    {
      enabled: +data.id_pp > 0,
    },
  );
}

export function useUpdateAnalTicket(isFilterOn: boolean, geoData?: IResearchGeoReq) {
  const queryClient = useQueryClient();
  return useMutation<any, any, IResearchSetTicketReq>((a) => analSetTicket(a), {
    onSuccess: async (res, req) => {
      if (isEmpty(geoData)) {
        queryClient.setQueryData<InfiniteData<IResearchResponse>>(
          [Keys.RESEARCH, "research", isFilterOn],
          (oldData: any) => {
            const newData = oldData?.pages.map((page) => replaceDashboardItem(page, req));
            return {
              ...oldData,
              pages: newData,
            };
          },
        );
      } else {
        queryClient.setQueryData<IResearch[]>([Keys.RESEARCH, "geo", geoData], (oldData: any) => {
          return replaceGeoItem(oldData, req);
        });
      }
    },
  });
}

export function useUpdateAnalStatus(isFilterOn: boolean, geoData?: IResearchGeoReq) {
  const queryClient = useQueryClient();
  return useMutation<any, any, IResearchSetStatusReq>((a) => analSetStatus(a), {
    onSuccess: async (res, req) => {
      if (isEmpty(geoData)) {
        queryClient.setQueryData<InfiniteData<IResearchResponse>>(
          [Keys.RESEARCH, "research", isFilterOn],
          (oldData: any) => {
            const newData = oldData?.pages.map((page) => replaceDashboardItem(page, req));
            return {
              ...oldData,
              pages: newData,
            };
          },
        );
      } else {
        queryClient.setQueryData<IResearch[]>([Keys.RESEARCH, "geo", geoData], (oldData: any) => {
          return replaceGeoItem(oldData, req);
        });
      }
    },
  });
}

export function useReportDashboard() {
  return useMutation<any, any, void>(() => researchReportDashboard(), {
    onSuccess: async (res) => {
      const url = window.URL.createObjectURL(res);
      const a = document.createElement("a");
      const dateString = format(new Date(), "dd.MM.yyyy_hh.mm");
      a.href = url;
      a.download = `Экспорт_${dateString}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
  });
}

export function useReportList() {
  return useMutation<any, any, number | string>((a) => researchReportList(a), {
    retryDelay: 90000,
    onSuccess: async (res) => {
      const url = window.URL.createObjectURL(res);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${res.filename}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
  });
}

export function useResearchFindOnMap() {
  return useMutation<{ url: string }, any, IResearchMapReq>((a) => researchFindOnMap(a), {
    mutationKey: [Keys.RESEARCH, "map"],
  });
}

export function useResearchFindGeo(data: IResearchGeoReq) {
  return useQuery<any, any, IResearch[]>([Keys.RESEARCH, "geo", data], () => researchFindGeo(data));
}

export function useResearchHandbook(a: string) {
  return useQuery<any, any, IResearchHandbookRes[]>([Keys.RESEARCH, "handbook", a], () =>
    researchHandbook(a),
  );
}

export function useResearchFavsList() {
  return useQuery<any, any, IResearchFavslistRes[]>([Keys.RESEARCH, "favlist"], () =>
    researchFavsList(),
  );
}
export function useResearchSingleFavlist(id: string | number, isEnabled = true) {
  return useQuery<string | number, any, number[]>(
    [Keys.RESEARCH, "singlefavlist", id],
    () => researchSingleFavlist(id),
    { enabled: isEnabled },
  );
}

export function useEditFavlist() {
  const queryClient = useQueryClient();
  return useMutation<any, any, IEditFavlistReq>((a) => researchEditFavlist(a), {
    onSuccess: async () => {
      toastSaveSuc();
      await queryClient.invalidateQueries([Keys.RESEARCH, "favlist"], { exact: true });
    },
    onError: () => {
      toastSaveFail();
    },
  });
}

export function useMutatDelFavlist(id: string | number) {
  const queryClient = useQueryClient();
  return useMutation(() => researchDeleteFavlist(id), {
    onSuccess: async () => {
      toastDelSuc();
      await queryClient.invalidateQueries([Keys.RESEARCH, "favlist"], { exact: true });
    },
    onError: () => {
      toastDelFail();
    },
  });
}

export function useSaveFilter() {
  const queryClient = useQueryClient();
  return useMutation<any, any, any>((a) => researchSaveFilter(a), {
    mutationKey: [Keys.RESEARCH, "filter", "save"],
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.RESEARCH, "research", true], { exact: true });
    },
  });
}

export function useLoadFilter(isEnabled = false) {
  return useQuery<any, any, any>([Keys.RESEARCH, "filter", "load"], () => researchLoadFilter(), {
    enabled: isEnabled,
  });
}

export function useSaveColumns() {
  const queryClient = useQueryClient();
  return useMutation<any, any, any>((a) => researchSaveColumns(a), {
    mutationKey: [Keys.RESEARCH, "columns", "save"],
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.RESEARCH, "columns", "check"], { exact: true });
      await queryClient.invalidateQueries([Keys.RESEARCH, "research", true], { exact: true });
    },
  });
}

export function useLoadColumns(isEnabled = false) {
  return useQuery<any, any, { [key: string]: boolean }>(
    [Keys.RESEARCH, "columns", "load"],
    () => researchLoadColumns(),
    {
      enabled: isEnabled,
    },
  );
}

export function useCheckVisibleColumns() {
  return useQuery<any, any, { [key: string]: true }>([Keys.RESEARCH, "columns", "check"], () =>
    researchCheckVisibleColumns(),
  );
}

export function useResearchPriemList(isEnabled = false, someArr: string[]) {
  return useQuery<string[] | undefined, any, IResearchPriemResponse>(
    [Keys.RESEARCH, "priem", someArr],
    () => getResearchPriemList(someArr),
    {
      enabled: isEnabled,
    },
  );
}

export function useResearchPoList(isEnabled = false) {
  return useQuery<any, any, IResearchPoResponse>([Keys.RESEARCH, "po"], () => getResearchPoList(), {
    enabled: isEnabled,
  });
}

export function useUpdateFb(isFilterOn: boolean) {
  const queryClient = useQueryClient();
  return useMutation<any, any, void>(() => analSetFb(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.RESEARCH, "research", isFilterOn], { exact: true });
    },
  });
}

export function useUpdateBd(isFilterOn: boolean) {
  const queryClient = useQueryClient();
  return useMutation<any, any, void>(() => analSetBd(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.RESEARCH, "research", isFilterOn], { exact: true });
    },
  });
}

export function useUpdateT2(isFilterOn: boolean) {
  const queryClient = useQueryClient();
  return useMutation<any, { description: string }, 1 | 2>((a) => analSetT2(a), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.RESEARCH, "research", isFilterOn], { exact: true });
    },
  });
}

export function useUpdateT3(isFilterOn: boolean) {
  const queryClient = useQueryClient();
  return useMutation<any, any, void>(() => analSetT3(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.RESEARCH, "research", isFilterOn], { exact: true });
    },
  });
}

export function useUpdateT0(isFilterOn: boolean) {
  const queryClient = useQueryClient();
  return useMutation<any, any, void>(() => analSetT0(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.RESEARCH, "research", isFilterOn], { exact: true });
    },
  });
}

export function useUpdateT1(isFilterOn: boolean) {
  const queryClient = useQueryClient();
  return useMutation<any, any, void>(() => analSetT1(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.RESEARCH, "research", isFilterOn], { exact: true });
    },
  });
}

export function useAnalStatRes() {
  return useQuery<any, any, IResearchStatResResponse[]>([Keys.RESEARCH, "stats", "res"], () =>
    getAnalStatRes(),
  );
}

export function useAnalStatInfo() {
  return useQuery<any, any, IResearchStatInfoRes>([Keys.RESEARCH, "stats", "info"], () =>
    getAnalStatInfo(),
  );
}

export function useAnalStatsArchive(id: string | number) {
  return useQuery<any, any, IResearchStatsArchiveRes>([Keys.RESEARCH, "stats", "archive", id], () =>
    getAnalStatsArchive(id),
  );
}
