import { IBreadcrumbsLink } from "interface/common";
import { DEPARTURE_RESULT } from "stream-constants/keys-and-constants";
import { ROUTE_BILLING_PAGE, ROUTE_SHOP_MANAGEMENT } from "stream-constants/route-constants";

export const links: IBreadcrumbsLink[] = [
  { name: "Управление", href: `/${ROUTE_BILLING_PAGE}` },
  { name: "Абоненты", href: `/${ROUTE_SHOP_MANAGEMENT}` },
];

export const calcBreadcrumbs = (entiny?: string | null) =>
  entiny != null && entiny !== "" ? links.concat({ name: entiny, href: "" }) : links;

export const FILTER_BY_STATUS = "accepted";

export const HARDCODE_STATUSES = {
  checkbox: {
    [FILTER_BY_STATUS]: [
      {
        id: 0,
        name: DEPARTURE_RESULT.ACTIVE,
      },
      {
        id: 1,
        name: DEPARTURE_RESULT.WAIT_CONFIRM,
      },
      {
        id: 2,
        name: DEPARTURE_RESULT.APPLICATION_REJECTED,
      },
      {
        id: 3,
        name: DEPARTURE_RESULT.ON_EDIT,
      },
    ],
  },
  radio: {},
};

export const TABLE_SORT_DEFAULT_ABONENT = [
  {
    accessor: "contract_num",
    text: "Номер договора",
  },
  {
    accessor: "username",
    text: "ФИО",
  },
  {
    accessor: "accepted",
    text: "Статус",
  },
];
