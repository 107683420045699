import { IAskueListResponse, IAskue, IAskueResponse, IAskueEventResponse } from "interface/askue";

const repeatArr = (arr: any[], times) => new Array(times).fill(arr).flat();

const fixed: IAskue[] = [
  {
    sn: "123844757766",
    meter_name: "СЕ208 R5.845.1.OP.Q PL04 IEC",
    dt_create: "2023-05-15 16:26:24.330000",
    dt_verify: "2023-05-19 15:27:25.323000",
    rele_status: 0,
    address: "Санкт-Петербург, ул. Торжковская, 15",
    port: 7500,
    actepr: 0.10222,
    id_channel_actepr: "365",
  },
  {
    sn: "1238111111",
    meter_name: "PQRSIN 5-60А",
    dt_create: "Wed, 5 April 2023 00:00:00 GMT",
    dt_verify: "Tue, 2 May 2023 00:00:00 GMT",
    rele_status: 1,
    address: "Москва, ул. Ленина, 1",
    port: 2500,
    actepr: 0.07361,
    id_channel_actepr: "654",
  },
  {
    sn: "333333333333",
    meter_name: "PQRSIN 100А",
    dt_create: "Wed, 5 April 2023 00:00:00 GMT",
    dt_verify: "Tue, 2 May 2023 00:00:00 GMT",
    rele_status: 1,
    address: "Москва, ул. Ленина, 1",
    port: 2500,
    actepr: 0.07361,
    id_channel_actepr: "421",
  },
];

export const askue_list_data: IAskueListResponse = {
  comment: "Какой-то комментарий",
  status: "Принята",
  created_time: "Wed, 5 April 2023 00:00:00 GMT",
  count: 3000,
  page_cnt: 3,
  offset: 1000,
  limit: 1000,
  results: repeatArr(fixed, 100),
};

export const askue_data: IAskueResponse = {
  status: 0,
  time_schema: "GMT+1 City",
  meter_count_all: 198,
  meter_count_unverified: 0,
  meter_new: 0,
  meter_types: [
    { meter_name: "M-41231", meter_count: 9 },
    { meter_name: "M-230ART", meter_count: 37 },
    { meter_name: "M-230ART", meter_count: 1 },
    { meter_name: "M-230ART", meter_count: 20 },
    { meter_name: "M-230ART", meter_count: 12 },
    { meter_name: "M-230ART", meter_count: 15 },
    { meter_name: "M-230ART", meter_count: 22 },
    { meter_name: "M-230ART", meter_count: 21 },
    { meter_name: "M-230ART", meter_count: 33 },
    { meter_name: "M-230ART", meter_count: 37 },
    { meter_name: "M-230ART", meter_count: 1 },
    { meter_name: "M-230ART", meter_count: 20 },
    { meter_name: "M-230ART", meter_count: 12 },
    { meter_name: "M-230ART", meter_count: 15 },
  ],
  active_ports: [
    {
      port: 7500,
      type: "UDP",
    },
    {
      port: 2500,
      type: "TCP",
    },
    {
      port: 4500,
      type: "TCP",
    },
    {
      port: 7500,
      type: "UDP",
    },
    {
      port: 7500,
      type: "TCP",
    },
    {
      port: 7500,
      type: "TCP",
    },
    {
      port: 3500,
      type: "UDP",
    },
    {
      port: 1000,
      type: "TCP",
    },
    {
      port: 7500,
      type: "TCP",
    },
    {
      port: 7500,
      type: "UDP",
    },
    {
      port: 5500,
      type: "TCP",
    },
  ],
  meter_rele_on: 30,
  meter_rele_off: 80,
};

export const mock_server_data = {
  activity_sessions: 2,
  activity_time: 29,
  server_name: "Server_0398",
  report_server_activity: [
    {
      time: "2023-07-31T12:42:12",
      value: 1,
    },
    {
      time: "2023-07-31T14:42:12",
      value: 1,
    },
    {
      time: "2023-07-31T16:42:12",
      value: 1,
    },
    {
      time: "2023-07-31T18:42:12",
      value: 0,
    },
    {
      time: "2023-07-31T20:42:12",
      value: 1,
    },
    {
      time: "2023-07-31T22:42:12",
      value: 1,
    },
    {
      time: "2023-08-01T00:42:12",
      value: 1,
    },
    {
      time: "2023-08-01T02:42:12",
      value: 0,
    },
    {
      time: "2023-08-01T04:42:12",
      value: 1,
    },
    {
      time: "2023-08-01T06:42:12",
      value: 1,
    },
    {
      time: "2023-08-01T08:42:12",
      value: 1,
    },
    {
      time: "2023-08-01T10:42:12",
      value: 0,
    },
  ],
  version_pk: "8.1.92.15194",
};

export const askue_events_data: IAskueEventResponse = {
  count: 3000,
  page_cnt: 3,
  offset: 1000,
  limit: 1000,
  results: [
    {
      datetime: "2024-01-05T10:00:00",
      event: "Замена чего-то 1",
      sn: "88005553555",
      meter_name: "М3-4Р2Д1",
      level: "Авария",
    },
    {
      datetime: "2024-01-04T10:00:00",
      event: "Замена чего-то 2",
      sn: "88005553554",
      meter_name: "М3-4Р2Д2",
      level: "Информация",
    },
    {
      datetime: "2024-01-10T10:00:00",
      event: "Замена чего-то 3",
      sn: "88005553553",
      meter_name: "М3-4Р2Д3",
      level: "Предупреждение",
    },
    {
      datetime: "2024-01-10T10:00:00",
      event: "Замена чего-то 3",
      sn: "88005553553",
      meter_name: "М3-4Р2Д3",
      level: "Предупреждение",
    },
    {
      datetime: "2024-01-11T10:00:00",
      event: "Замена чего-то 4",
      sn: "88005553554",
      meter_name: "М3-4Р2Д4",
      level: "Предупреждение",
    },
  ],
};

export const mock_meters_name = { meter_names: ["М3-4Р2Д4", "М3-4Р2Д3", "М3-4Р2Д2", "М3-4Р2Д1"] };
