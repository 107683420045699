import { FC, Fragment } from "react";

import { useDeleteAbonentDevice } from "hooks/api/management";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { IAbonentSn } from "interface/management";
import { SBtns, SSubtitle, STop } from "./styles";

type IProps = {
  data: IAbonentSn;
  onCancel: () => void;
};
export const FormDeleteSn: FC<IProps> = ({ data, onCancel }) => {
  const mutation = useDeleteAbonentDevice(data);

  return (
    <>
      <STop>
        <CusTypo variant="p1_medium" font="comp">
          Закрыть доступ к устройству
        </CusTypo>
        <Spacer value="8px" />
        <SSubtitle variant="p4_regular">
          Абонент больше не сможет просматривать данные полученные от этого устройства.
        </SSubtitle>
        <Spacer value="24px" />
      </STop>
      <SBtns>
        <MTSButton
          size="M"
          variant="primary"
          type="submit"
          onClick={() => {
            mutation.mutate(data);
            onCancel();
          }}
        >
          Закрыть доступ
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={onCancel}>
          Отмена
        </MTSButton>
      </SBtns>
    </>
  );
};
