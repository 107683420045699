import { FC } from "react";

export interface ILocationState {
  e_object_id?: string;
  tenant_id?: string;
  tenant_name?: string;
  device_sn?: string;
  is_anomap?: boolean;
}

export interface IDataSidebar {
  id: string;
  title: string;
  link: string;
  subitem?: ISidebarSubitem[];
  forRoles: string[];
  forServices?: string[];
}

export interface ISidebarSubitem {
  name: string;
  link: string;
  forRoles: string[];
  forServices?: string[];
}

export interface IBreadcrumbsLink {
  name: string;
  href: string;
}
export interface ITenants {
  title: string;
  id: string;
}
export interface IRoles {
  id: number;
  name: string;
}

export interface ITenantFull {
  email: string;
  id: string;
  phone: string | null;
  title: string;
  services?: any[] | null; // нам не актуально для текущих задач
}

export interface IProfileStruct {
  createdTime: string;
  email: string;
  id: number;
  isActive: boolean;
  phone: string;
  tenant: ITenantFull;
  tenants: ITenants[];
  userName: string;
  roles: IRoles[];
  services_ch: string[]; // ['Поиск коммерческих потерь'] и тд
  current_tenant: ITenants[];
}

export interface IPopupItem {
  title?: string;
  tenantId?: string | number;
}

export interface IAccordionFilteItem {
  id: number | string;
  name: string;
  checked: boolean;
}

export interface IAccordionCustom {
  key: string | number;
  onToggle?: (val: number) => void;
  index: number;
  headerContent?: FC;
  bodyContent?: FC;
}

export interface IAccordionItemCustom {
  key: string | number;
  active?: boolean;
  onToggle?: (val: number) => void;
  headerContent?: FC;
  bodyContent?: FC;
}

export enum ESn {
  Низкая = "Низкая",
  Средняя = "Средняя",
  Высокая = "Высокая",
  Фрод = "Фрод",
}

export enum EChartRates {
  common = 0,
  day = 1,
  night = 2,
}

export enum EDemoStatus {
  REJECTED = "REJECTED", // форма и xlsx не прошли модерацию
  ONREVIEW = "ONREVIEW",
  SUCC = "SUCC", // модерация пройдена, данные доступны
  START = "START", // шаг заполнения формы
}

export enum EHandbookLitrs {
  "Ср.день" = "Ср.день",
  "Ср.ночь" = "Ср.ночь",
  "День/ночь" = "День/ночь",
  "Размах" = "Размах",
  "Кратность" = "Кратность",
  "Периодич.>0.4" = "Периодич.>0.4",
}

export enum EDemoMngStatus {
  "Заполнение анкеты" = "Заполнение анкеты",
  "На согласовании" = "На согласовании",
  "На модерации" = "На модерации",
  Активен = "Активен",
  Приостановлен = "Приостановлен",
}

export enum EDqScore {
  Подходит = "Подходит",
  Допустимо = "Допустимо",
  "Не подходит" = "Не подходит",
  "В процессе" = "В процессе",
}
