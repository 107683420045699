import { useCallback, useContext, useMemo, useState } from "react";

import { ITableFilterClm, ITableSortColumn, Table } from "components/table/table.component";
import { ContextSettings } from "context/context-settings";
import { constructColumnsSn, constructTableDataSn } from "./utils";
import { MobileTable } from "components/table/mobile-table/mobileTable.component";
import { IAskue } from "interface/askue";
import { tableSnMobStructure, tableStructureSn } from "./const";

interface IProp {
  rawData: IAskue[];
  filterByColumn?: ITableFilterClm[];
  globalFilter?: string;
  onRowsChange?: Function;
  sortByColumn?: ITableSortColumn[];
  savedId?: number;
}
export const AbonentSnTable: React.FC<IProp> = ({
  rawData,
  filterByColumn,
  globalFilter,
  onRowsChange,
  sortByColumn,
  savedId,
}) => {
  const isSingleItem = rawData.length === 1;
  const { isDesktop } = useContext(ContextSettings);
  const [active, setActive] = useState({});

  const tableData = useMemo(
    () => constructTableDataSn(rawData ?? [{}], savedId!, isSingleItem),
    [rawData, savedId, isSingleItem],
  );
  const columns = useMemo(
    () => constructColumnsSn({ columns: tableStructureSn, active }, savedId!, isSingleItem),
    [active, savedId, isSingleItem],
  );
  const mobileColumns = useMemo(() => {
    return isSingleItem
      ? tableSnMobStructure.filter((col) => col.accessor !== "action")
      : tableSnMobStructure;
  }, [isSingleItem]);

  const clickDesktop = useCallback((id) => setActive((a) => ({ [id]: !a[id] })), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRowsChange = useCallback((a) => onRowsChange?.(a), []);

  return isDesktop() ? (
    <div>
      <Table
        view="alternatingRows"
        columns={columns}
        data={tableData}
        globalFilter={globalFilter}
        height={220}
        filterByColumn={filterByColumn}
        onRowClick={clickDesktop}
        onRowsChange={handleRowsChange}
        savedId={savedId}
      />
    </div>
  ) : (
    <MobileTable
      columns={mobileColumns}
      data={tableData}
      filterByColumn={filterByColumn}
      sortByColumn={sortByColumn}
      onRowsChange={handleRowsChange}
      globalFilter={globalFilter}
      padding="0 20px"
      isSingleItem={isSingleItem}
    />
  );
};
