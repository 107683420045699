import { IHttpMethods } from "stream-constants/server-constants";
import {
  ABONENT_DEL_ABONENT,
  ABONENT_DEL_SN,
  ABONENT_MANAGEMENT,
  ABONENT_UPDATE_APP,
  ABONENT_UPDATE_APP_STATUS,
} from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { mock_data } from "./mock-data";
import { IAbonentSn, IUpdateAbonentRequest, IUpdateStatusAppRequest } from "interface/management";

export const getManagementAbonents = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_data;
  } else {
    result = await fetchData({
      url: ABONENT_MANAGEMENT,
      method: IHttpMethods.GET,
      name: "getManagementAbonent",
      emptyResponse: false,
    });
  }

  return result;
};

export const deleteAbonentDevice = async (data: IAbonentSn) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: ABONENT_DEL_SN,
      method: IHttpMethods.DELETE,
      name: "deleteAbonentDevice",
      emptyResponse: false,
      body: JSON.stringify(data),
    });
  }

  return result;
};

export const updateAbonent = async (data: IUpdateAbonentRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: ABONENT_UPDATE_APP,
      method: IHttpMethods.PATCH,
      name: "updateAbonent",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const updateStatusApplication = async (data: IUpdateStatusAppRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: ABONENT_UPDATE_APP_STATUS,
      method: IHttpMethods.PATCH,
      name: "updateStatusApp",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const deleteAbonent = async (id: number) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: ABONENT_DEL_ABONENT,
      method: IHttpMethods.DELETE,
      name: "deleteAbonent",
      emptyResponse: false,
      body: JSON.stringify({ user_id: id }),
    });
  }

  return result;
};
