import theme from "theme";
import { format, isEqual } from "date-fns";
import { IAskue } from "interface/askue";

import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { SelectAction } from "./subComponent/selectAction";
import { CusTypo } from "components/cusTypo/custom-typography";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { SColored } from "./styles";

export enum ERele {
  "Реле отключено" = 0,
  "Реле включено" = 1,
  "Новое устройство" = 2,
}

export type IReleStatus = "Реле включено" | "Реле отключено" | "Новое устройство";
export const releStatus = (val: number) => {
  switch (val) {
    case ERele["Реле включено"]:
      return "Реле включено";
    case ERele["Реле отключено"]:
      return "Реле отключено";
    case ERele["Новое устройство"]:
      return "Новое устройство";
    default:
      return EMPTY_CELL_VALUE;
  }
};

export const colorByRele = (val) => {
  switch (val) {
    case "Реле включено":
      return theme.mtsColor.text.positive.lightMode;
    case "Реле отключено":
      return theme.mtsColor.accent.negative.lightMode;
    default:
      return theme.mtsColor.grey.greyDark;
  }
};

export const constructTableData = (draft: IAskue[]) =>
  draft?.map((d) => {
    const { dt_create, dt_verify, actepr } = d;

    return {
      ...d,
      actepr: actepr ? actepr.toFixed(2) : null,
      dt_create: new Date(dt_create ?? 0),
      dt_verify: new Date(dt_verify ?? 0),
    };
  });

export const constructColumns = ({ columns }) => {
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "sn"
        ? {
            Cell: ({ value }) => (
              <CopyToClipboard text={value ?? EMPTY_CELL_VALUE} variant="p4_medium" />
            ),
          }
        : singleColumn.accessor === "dt_verify" || singleColumn.accessor === "dt_create"
        ? {
            Cell: ({ value }) => (
              <CusTypo variant="c1_regular">
                {isEqual(value, new Date(0)) ? EMPTY_CELL_VALUE : format(value, "dd.MM.yyyy")}
              </CusTypo>
            ),
          }
        : singleColumn.accessor === "rele_status"
        ? {
            Cell: ({ value }) => (
              <SColored variant="c1_regular" status={releStatus(value) as IReleStatus}>
                {releStatus(value) ?? EMPTY_CELL_VALUE}
              </SColored>
            ),
          }
        : singleColumn.accessor === "action"
        ? {
            Cell: ({ row }) => <SelectAction id={row.original.sn} />,
          }
        : { Cell: ({ value }) => value ?? EMPTY_CELL_VALUE }),
    };
  });
};
