import { format, isEqual } from "date-fns";

import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { DEPARTURE_RESULT, EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { ERele, releStatus, IReleStatus } from "../utils";
import { SCont, SItems } from "./substyles";
import { SColored } from "../styles";

export const MobDevice = ({ row, isAbonent }) => {
  const { sn, meter_name, dt_create, actepr, dt_verify, rele_status, address } = row.original;
  const isExistDevice = ERele[rele_status] !== DEPARTURE_RESULT.NEW_DEVICE;

  return (
    <SCont>
      <section>
        <CusTypo variant="p3_bold">{sn}</CusTypo>
        <Spacer value="8px" />
        {isExistDevice ? (
          <CusTypo variant="c1_regular">{meter_name ?? EMPTY_CELL_VALUE}</CusTypo>
        ) : null}
      </section>
      <Spacer value="12px" />
      <SItems>
        {isExistDevice ? (
          <>
            <div>
              <CusTypo variant="c1_regular" font="comp">
                Дата подключения
              </CusTypo>
              <CusTypo variant="c1_regular">
                {isEqual(dt_create, new Date(0))
                  ? EMPTY_CELL_VALUE
                  : format(dt_create, "dd.MM.yyyy")}
              </CusTypo>
            </div>
            <div>
              <CusTypo variant="c1_regular" font="comp">
                {isAbonent ? "кВт*ч" : "А прием, кВт*ч"}
              </CusTypo>
              <CusTypo variant="c1_regular">{actepr ?? EMPTY_CELL_VALUE}</CusTypo>
            </div>
            <div>
              <CusTypo variant="c1_regular" font="comp">
                {isAbonent ? "Последняя передача данных" : "Дата последней передачи данных"}
              </CusTypo>
              <CusTypo variant="c1_regular">
                {isEqual(dt_verify, new Date(0))
                  ? EMPTY_CELL_VALUE
                  : format(dt_verify, "dd.MM.yyyy")}
              </CusTypo>
            </div>
          </>
        ) : null}
        <div>
          <CusTypo variant="c1_regular" font="comp">
            {isAbonent ? "Реле нагрузки" : "Статус"}
          </CusTypo>
          <SColored variant="c1_regular" status={releStatus(rele_status) as IReleStatus}>
            {releStatus(rele_status)}
          </SColored>
        </div>
        {isExistDevice ? (
          <div>
            <CusTypo variant="c1_regular" font="comp">
              Адрес
            </CusTypo>
            <CusTypo variant="c1_regular">{address ?? EMPTY_CELL_VALUE}</CusTypo>
          </div>
        ) : null}
      </SItems>
    </SCont>
  );
};
