import { IHttpMethods } from "stream-constants/server-constants";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { fetchData } from "services/services-utils/fetch-data";
import {
  ABONENT_ADD_DEVICE,
  ABONENT_ADD_NEW_SN,
  ABONENT_CHECK_SN,
  ABONENT_DEVICE_LIST,
  ABONENT_EVENTS,
  ABONENT_REPORT_XLSX,
  ABONENT_STATUS,
  ASKUE_DEVICES,
  ASKUE_DEVICES_XLSX,
  ASKUE_EVENTS,
  ASKUE_EVENT_XLSX,
  ASKUE_GET_XLSX,
  ASKUE_INFO,
  ASKUE_METER_TYPES,
  ASKUE_RELE_LOG,
  ASKUE_SERVER,
  ASKUE_UPLOAD_XLSX,
} from "services/urls";
import {
  IAskueAbonReportRequest,
  IAskueEventRequest,
  IAskueRequest,
  IAskueUpload,
} from "interface/askue";
import { IAbonentAddDeviceReq, IAskueCheckSnReq } from "interface/abonent";
import {
  askue_list_data,
  askue_data,
  mock_server_data,
  askue_events_data,
  mock_meters_name,
} from "./mock-data";
import { IAbonentSn } from "interface/management";

export const getAskueList = async (data?: IAskueRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = askue_list_data;
  } else {
    result = await fetchData({
      url: ASKUE_DEVICES,
      method: IHttpMethods.POST,
      name: "getAskueList",
      body: JSON.stringify(data),
      emptyResponse: false,
      responseAbordTimeOut: false,
    });
  }
  return result;
};

export const getAskueXlsx = async () => {
  let result;

  result = await fetchData({
    url: ASKUE_GET_XLSX,
    method: IHttpMethods.GET,
    name: "getAskueTemplateXlsx",
    emptyResponse: false,
    blobResponse: true,
    responseAbordTimeOut: false,
  }).then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `askue_template.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });

  return result;
};

export const askueUploadStatement = async (data: IAskueUpload) => {
  return fetchData({
    url: ASKUE_UPLOAD_XLSX,
    method: IHttpMethods.POST,
    name: "askueUploadStatement",
    body: data,
    emptyResponse: false,
    contentType: "none",
  });
};

export const getAskue = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = askue_data;
  } else {
    result = await fetchData({
      url: ASKUE_INFO,
      method: IHttpMethods.GET,
      name: "getAskue",
      emptyResponse: false,
      responseAbordTimeOut: false,
    });
  }
  return result;
};

export const getAskueServer = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_server_data;
  } else {
    result = await fetchData({
      url: ASKUE_SERVER,
      method: IHttpMethods.GET,
      name: "getAskueServer",
      emptyResponse: false,
    });
  }
  return result;
};

export const getAskueReleLog = async (data: { sn: string }) => {
  return await fetchData({
    url: ASKUE_RELE_LOG,
    method: IHttpMethods.POST,
    name: "getAskueReleLog",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
};

export const getAskueEvents = async (data?: IAskueEventRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = askue_events_data;
  } else {
    result = await fetchData({
      url: ASKUE_EVENTS,
      method: IHttpMethods.POST,
      name: "getAskueEvents",
      body: JSON.stringify(data),
      emptyResponse: false,
      responseAbordTimeOut: false,
    });
  }

  return result;
};

export const askueGetEventsXlsx = async (data?: IAskueEventRequest) => {
  let result;

  result = await fetchData({
    url: ASKUE_EVENT_XLSX,
    method: IHttpMethods.POST,
    name: "askueEventsLogXlsx",
    body: JSON.stringify(data),
    emptyResponse: false,
    blobResponse: true,
    responseAbordTimeOut: false,
  }).then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `askue_log.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });

  return result;
};

export const askueGetDeviceListXlsx = async (data?: IAskueRequest) => {
  return await fetchData({
    url: ASKUE_DEVICES_XLSX,
    method: IHttpMethods.POST,
    name: "askueEventsLogXlsx",
    body: JSON.stringify(data),
    emptyResponse: false,
    blobResponse: true,
    responseAbordTimeOut: false,
  }).then((blob) => {
    return blob;
  });
};

export const getCheckSn = async (data: IAskueCheckSnReq) => {
  return await fetchData({
    url: ABONENT_CHECK_SN,
    method: IHttpMethods.POST,
    name: "getCheckSn",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
};

export const getAddAbonentDevice = async (data: IAbonentAddDeviceReq) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: ABONENT_ADD_DEVICE,
      method: IHttpMethods.POST,
      name: "formAddAbonentDevice",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const getAskueStatus = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = { status: 0, comment: "Неверно указан номер договора" };
  } else {
    result = await fetchData({
      url: ABONENT_STATUS,
      method: IHttpMethods.GET,
      name: "getAbonentStatus",
      emptyResponse: false,
    });
  }
  return result;
};

export const getMetersName = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_meters_name;
  } else {
    result = await fetchData({
      url: ASKUE_METER_TYPES,
      method: IHttpMethods.GET,
      name: "getMetersName",
      emptyResponse: false,
    });
  }
  return result;
};

export const getAskueAbonentEvents = async (data?: IAskueEventRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = askue_events_data;
  } else {
    result = await fetchData({
      url: ABONENT_EVENTS,
      method: IHttpMethods.POST,
      name: "getAskueAbonentEvents",
      body: JSON.stringify(data),
      emptyResponse: false,
      responseAbordTimeOut: false,
    });
  }

  return result;
};

export const getAbonentDeviceList = async (id?: string | number) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = askue_list_data;
  } else {
    result = await fetchData({
      url: id ? `${ABONENT_DEVICE_LIST}/?user_id=${id}` : ABONENT_DEVICE_LIST,
      method: IHttpMethods.GET,
      name: "getAbonentDeviceList",
      emptyResponse: false,
    });
  }

  return result;
};

export const askueGetAbonReportXlsx = async (data?: IAskueAbonReportRequest) => {
  return await fetchData({
    url: ABONENT_REPORT_XLSX,
    method: IHttpMethods.POST,
    name: "askueAbonentReportXlsx",
    body: JSON.stringify(data),
    emptyResponse: false,
    blobResponse: true,
    responseAbordTimeOut: false,
  }).then((blob) => {
    return blob;
  });
};

export const getAddNewAbonentDevice = async (data: IAbonentSn) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: ABONENT_ADD_NEW_SN,
      method: IHttpMethods.POST,
      name: "addNewAbonentDevice",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};
