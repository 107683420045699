import { useContext, useEffect, useRef, useState } from "react";
import { formatISO } from "date-fns";
import { isEmpty } from "lodash";

import { useAskueAbonentEvents } from "hooks/api/abonent";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Footer } from "components/footer/footer";
import { Loader } from "components/loader/loader";
import { MTSInput } from "components/mts-input";
import { ContextSettings } from "context/context-settings";
import { useDebounce } from "hooks/useDebounce";
import { InputDatePicker } from "components/mts-datepicker/mts-datepicker";
import { AskueEventsTable } from "components/askue/askueEventsTable/askueEventsTable.component";
import { TableFilter } from "components/table/table-filter/table-filter";
import { CusTypo } from "components/cusTypo/custom-typography";
import {
  ASKUE_LEVEL_COLUMN,
  TABLE_FILTER_DEFAULT_EVENTS,
  composeBackendFilter,
  defaultPeriod,
  links_events,
} from "./const";
import { SArticle, SBtns, SDash, SFields, SItem, SPos, SSection, STitle } from "./styles";

export const AskueAbonentEvents = () => {
  const { isDesktop } = useContext(ContextSettings);
  const [searchVal, setSearch] = useState("");
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [period, setPeriod] = useState<any>(defaultPeriod);
  const [columnFilter, setColumnFilter] = useState<any>([]);
  const debouncedSearch = useDebounce(searchVal, 300);
  const [dataLength, setDataLength] = useState(undefined);

  const isFirstQuery = useRef<boolean>(true);
  const schemeRef = useRef<any>({
    checkbox: {
      ...ASKUE_LEVEL_COLUMN,
    },
    radio: {},
  });

  const { data, fetchNextPage, isLoading } = useAskueAbonentEvents({
    ...composeBackendFilter([...columnFilter, { column: "sn", value: debouncedSearch }]),
    period: period["period"],
  });

  const eventsList = data?.pages.flatMap((p) => p.results) ?? [];

  const handleDatesPeriod = (e, index) => {
    let start = period["period"];
    start[index] = formatISO(e);
    setPeriod({ period: start });
  };

  const handleColumnFilter = ({ filtersArr }) => {
    setColumnFilter(filtersArr);
  };

  const getDataLength = (a) => setDataLength(a.length);

  const handleInput = ({ target: { value } }) =>
    setSearch((prev) => (/\d+/.test("" + Number(value)) ? value : prev));

  useEffect(() => {
    if (isFirstQuery.current && !isEmpty(eventsList)) {
      isFirstQuery.current = false;

      schemeRef.current = {
        checkbox: {
          ...ASKUE_LEVEL_COLUMN,
        },
        radio: {},
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsList.length]);

  return (
    <>
      <div>
        <SSection>
          <Breadcrumbs links={links_events} />
          <SArticle>
            <STitle>
              <CusTypo
                variant={isDesktop() ? "h2_medium" : "h4_medium"}
                font="wide"
                styles={{ margin: "0 24px 0 0", whiteSpace: "nowrap" }}
              >
                Журнал событий
              </CusTypo>
            </STitle>
            <SFields>
              <>
                <SItem className="searchField">
                  <MTSInput
                    searchIcon
                    placeholder="Серийный номер, адрес"
                    onChange={handleInput}
                    value={searchVal}
                    size="S"
                  />
                </SItem>
                <SItem>
                  <SPos>
                    <CusTypo variant="p4_medium">Период</CusTypo>
                    <InputDatePicker
                      placeholder="ДД.ММ.ГГ"
                      onSelect={(e) => handleDatesPeriod(e, 0)}
                      isPickAM
                    />
                    <SDash>
                      <CusTypo variant="p4_bold">-</CusTypo>
                    </SDash>
                    <InputDatePicker
                      placeholder="ДД.ММ.ГГ"
                      onSelect={(e) => handleDatesPeriod(e, 1)}
                      isPickAM
                    />
                  </SPos>
                </SItem>
              </>
            </SFields>
            <SBtns>
              <TableFilter
                className="btns__filter"
                dataLength={dataLength ?? 0}
                isOpen={isOpenFilter}
                isOn={true}
                defaultFilter={TABLE_FILTER_DEFAULT_EVENTS}
                toogleShowFilter={setIsOpenFilter}
                onApplyFilter={handleColumnFilter}
                scheme={schemeRef.current}
                isSchemeOnly
              />
            </SBtns>
          </SArticle>
        </SSection>
        <AskueEventsTable
          rawData={eventsList ?? []}
          onRowsChange={getDataLength}
          onEndReach={fetchNextPage}
        />
      </div>
      <Footer />
      {isLoading ? <Loader isFullPage /> : null}
    </>
  );
};
