import { useRef, useState } from "react";

import { IMngAbonentResponse } from "interface/management";
import { MTSModal } from "components/mts-modal/mts-modal";
import { AbonentMobCard, AbonentSnMobCard } from "./subcomponents";
import { Spacer } from "components/spacer/spacer.component";
import { FormDeleteAbonent } from "components/forms/abonent-management/form-del-abonent";
import { FormEditAbonent } from "components/forms/abonent-management/form-edit-abonent";
import { FormAddSn } from "components/forms/abonent-management/form-add-sn";
import {
  AbonDetailsAccept,
  AbonDetailsWaitConfirm,
  AbonDetailsWaitReject,
} from "./abonent.component";
import { FormDeleteSn } from "components/forms/abonent-management/form-del-sn";
import { EAbonentStatus } from "components/management-abonent/const";
import { SCont, SPage } from "./styles";

export const MobAbonent = ({ data }: { data: IMngAbonentResponse }) => {
  const refInner = useRef<any>(null);

  const isAppReject = data.accepted === EAbonentStatus["Заявка отклонена"];
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalAddDevice, setShowModalAddDevice] = useState(false);

  const toggleShowModalDelete = () => setShowModalDelete(!showModalDelete);
  const toggleShowModalEdit = () => setShowModalEdit(!showModalEdit);
  const toggleShowModalAddDevice = () => setShowModalAddDevice(!showModalAddDevice);

  const editFunc = {
    title: "Редактировать данные",
    onClick: toggleShowModalEdit,
  };

  const addFunc = {
    title: "Добавить доступное устройсво",
    onClick: toggleShowModalAddDevice,
  };

  const delFunc = {
    title: "Удалить",
    onClick: toggleShowModalDelete,
  };

  const dataPopup = isAppReject ? [delFunc] : [editFunc, addFunc, delFunc];

  return (
    <>
      <SPage>
        <AbonentMobCard data={data} dataPopup={dataPopup} />
        <Spacer value="24px" />
        <SCont onClick={(event) => event.stopPropagation()} ref={refInner}>
          {data.accepted === EAbonentStatus["Ожидает подтверждения"] ? (
            <AbonDetailsWaitConfirm data={data} />
          ) : data.accepted === EAbonentStatus["Заявка отклонена"] ? (
            <AbonDetailsWaitReject data={data} />
          ) : (
            <AbonDetailsAccept data={data} />
          )}
        </SCont>
      </SPage>
      <MTSModal open={showModalDelete} close={toggleShowModalDelete}>
        <FormDeleteAbonent onCancel={toggleShowModalDelete} id={data.user_id} />
      </MTSModal>
      <MTSModal open={showModalEdit} close={toggleShowModalEdit}>
        <FormEditAbonent onCancel={toggleShowModalEdit} data={data} />
      </MTSModal>
      <MTSModal open={showModalAddDevice} close={toggleShowModalAddDevice}>
        <FormAddSn userId={data.user_id} onCancel={toggleShowModalAddDevice} />
      </MTSModal>
    </>
  );
};

export const AbonentSnMobileCard = ({ row }) => {
  const { sn, user_id, isSingleItem } = row.original;
  const [showModalDelete, setShowModalDelete] = useState(false);

  const toggleShowModalDelete = () => setShowModalDelete(!showModalDelete);

  const dataDel = {
    sn: Number(sn),
    user_id: user_id,
  };

  const delFunc = {
    title: "Удалить",
    onClick: toggleShowModalDelete,
  };

  return (
    <>
      <AbonentSnMobCard data={row.original} dataPopup={[delFunc]} withoutAction={isSingleItem} />
      <MTSModal open={showModalDelete} close={toggleShowModalDelete}>
        <FormDeleteSn data={dataDel} onCancel={toggleShowModalDelete} />
      </MTSModal>
    </>
  );
};
