import { FC, Fragment } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { IMngAbonentResponse } from "interface/management";
import { useUpdateStatusApplication } from "hooks/api/management";
import { Toast } from "components/toast/toast";
import { EAbonentStatus } from "components/management-abonent/const";
import { SBtns, SSubtitle, STop } from "./styles";
import { Keys } from "hooks/api/const";

type IProps = {
  data: IMngAbonentResponse;
  onCancel: () => void;
};
export const FormAcceptApplication: FC<IProps> = ({ data, onCancel }) => {
  const queryClient = useQueryClient();
  const mutation = useUpdateStatusApplication();

  const onSubmit = () => {
    mutation.mutate(
      {
        status: EAbonentStatus["Активен"],
        user_id: data.user_id,
      },
      {
        onSuccess: async () => {
          toastSucc();
          await queryClient.invalidateQueries([Keys.ABONENT], { exact: true });
          onCancel();
        },
        onError: () => {
          toastErr();
          onCancel();
        },
      },
    );
  };

  return (
    <>
      <STop>
        <CusTypo variant="p1_medium" font="comp">
          Принять заявку?
        </CusTypo>
        <Spacer value="8px" />
        <SSubtitle variant="p4_regular">
          Абоненту станет доступен личный кабинет и возможность просматривать данные доступных
          устройств
        </SSubtitle>
        <Spacer value="24px" />
      </STop>
      <SBtns>
        <MTSButton size="M" variant="primary" type="submit" onClick={onSubmit}>
          Подтвердить
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={onCancel}>
          Отмена
        </MTSButton>
      </SBtns>
    </>
  );
};

export const toastSucc = () => {
  toast(<Toast title="Заявка принята" />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};

export const toastErr = () => {
  toast(<Toast title="Заявка не принята" isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};
